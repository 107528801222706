import React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const columns = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "name",
    headerName: "Name",
    width: 150,
    editable: true,
  },
  {
    field: "display_name",
    headerName: "Display name",
    width: 150,
    editable: true,
  },
  {
    field: "user_passport",
    headerName: "Mobile",
    width: 180,
    editable: true,
  },
  //   {
  //     field: "email",
  //     headerName: "email",
  //     type: "email",
  //     description: "This column has a value getter and is not sortable.",
  //     sortable: true,
  //     width: 160,
  //   },
  //   {
  //     field: "organisation",
  //     headerName: "Organisation",
  //     width: 150,
  //     editable: true,
  //   },
  {
    field: "referral",
    headerName: "Referral",
    type: "number",
    width: 150,
    editable: true,
  },
  {
    field: "convertedReferral",
    headerName: "Converted Referral",
    type: "number",
    width: 150,
    editable: true,
  },
  //   {
  //     field: "status",
  //     headerName: "Status",
  //     width: 150,
  //     editable: true,
  //   },
  //   {
  //     field: "quiz",
  //     headerName: "Quiz",
  //     width: 150,
  //     editable: true,
  //   },
  //   {
  //     field: "highestScore",
  //     headerName: "Highest Score",
  //     type: "number",
  //     width: 150,
  //     editable: true,
  //   },
  //   {
  //     field: "rank",
  //     headerName: "Rank",
  //     width: 150,
  //     type: "number",
  //     editable: true,
  //   },
  //   {
  //     field: "attempts",
  //     headerName: "Attempts",
  //     width: 150,
  //     type: "number",
  //     editable: true,
  //   },
  //   {
  //     field: "lastAttempt",
  //     headerName: "Last Attempt",
  //     width: 150,
  //     editable: true,
  //   },
  //   {
  //     field: "lastScore",
  //     headerName: "Last Score",
  //     type: "number",
  //     width: 150,
  //     editable: true,
  //   },
];

const rows = [
  {
    id: 1,
    name: "Jon Snow",
    displayName: "Snow",
    mobile: "2348060678720",
    email: "dfmope@gmail.com",
    organisation: "Lorem ",
    referral: 12,
    convertedReferral: 8,
    status: "active",
    quiz: "tosse challenge",
    highestScore: 120,
    rank: 2,
    attempts: 3,
    lastAttempt: 200,
    lastScore: 30,
  },
  {
    id: 2,
    name: "Arya Stark",
    displayName: "Stark",
    mobile: "2348060678721",
    email: "aryastark@gmail.com",
    organisation: "Winterfell",
    referral: 8,
    convertedReferral: 4,
    status: "active",
    quiz: "tosse challenge",
    highestScore: 110,
    rank: 3,
    attempts: 2,
    lastAttempt: 100,
    lastScore: 20,
  },
  {
    id: 3,
    name: "Sansa Stark",
    displayName: "Sansa",
    mobile: "2348060678722",
    email: "sansastark@gmail.com",
    organisation: "Winterfell",
    referral: 125,
    convertedReferral: 53,
    status: "inactive",
    quiz: "tosse challenge",
    highestScore: 100,
    rank: 4,
    attempts: 1,
    lastAttempt: 50,
    lastScore: 10,
  },
  {
    id: 4,
    name: "Bran Stark",
    displayName: "Bran",
    mobile: "2348060678723",
    email: "branstark@gmail.com",
    organisation: "Winterfell",
    referral: 67,
    convertedReferral: 36,
    status: "active",
    quiz: "tosse challenge",
    highestScore: 90,
    rank: 5,
    attempts: 0,
    lastAttempt: null,
    lastScore: null,
  },
  {
    id: 5,
    name: "Robb Stark",
    displayName: "Robb",
    mobile: "2348060678724",
    email: "robbstark@gmail.com",
    organisation: "Winterfell",
    referral: 0,
    convertedReferral: 0,
    status: "active",
    quiz: "tosse challenge",
    highestScore: 80,
    rank: 6,
    attempts: 4,
    lastAttempt: 150,
    lastScore: 40,
  },
  {
    id: 6,
    name: "Catelyn Stark",
    displayName: "Catelyn",
    mobile: "2348060678725",
    email: "catelynstark@gmail.com",
    organisation: "Winterfell",
    referral: 53,
    convertedReferral: 32,
    status: "active",
    quiz: "tosse challenge",
    highestScore: 70,
    rank: 7,
    attempts: 1,
    lastAttempt: 75,
    lastScore: 15,
  },
  // { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
  // { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
  // { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
  // { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
  // { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
  // { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
  // { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
  // { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
];

export default function ReferralTable({ raw, rows = [] }) {
  return (
    <Box sx={{ height: 400, width: "100%", backgroundColor: "white" }}>
      <DataGrid
        slots={{ toolbar: GridToolbar }}
        rows={rows}
        loading={raw?.isLoading}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
        pageSizeOptions={[20]}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
  );
}
