import React from "react";
import { NavLink } from "react-router-dom";

//STYLES
import classes from "./sidebar.module.css";

const Sidebar = () => {
  let activeStyle = {
    fontWeight: "bold",
    color: "white",
  };

  let defaultStyle = {
    fontWeight: "bold",
    color: "black",
  };

  return (
    <div className={classes.sidebar}>
      <ul>
        <NavLink
          to="/admin/summary"
          className="navBtn"
          style={({ isActive }) => (isActive ? activeStyle : defaultStyle)}
        >
          <li>Dashboard</li>
        </NavLink>
        <NavLink
          to="/admin/stats"
          className="navBtn"
          style={({ isActive }) => (isActive ? activeStyle : defaultStyle)}
        >
          <li>Competition</li>
        </NavLink>

        <NavLink
          to="/admin/subscribers"
          className="navBtn"
          style={({ isActive }) => (isActive ? activeStyle : defaultStyle)}
        >
          <li>Subscribers</li>
        </NavLink>
        <NavLink
          to="/admin/referrals"
          className="navBtn"
          style={({ isActive }) => (isActive ? activeStyle : defaultStyle)}
        >
          <li>Referrals</li>
        </NavLink>

        <NavLink
          to="/admin/games"
          className="navBtn"
          style={({ isActive }) => (isActive ? activeStyle : defaultStyle)}
        >
          <li>Games</li>
        </NavLink>

        <NavLink
          to="/admin/plans"
          className="navBtn"
          style={({ isActive }) => (isActive ? activeStyle : defaultStyle)}
        >
          <li>Plans</li>
        </NavLink>
      </ul>
    </div>
  );
};

export default Sidebar;
