import React, { useContext } from "react";

//STYLES
import classes from "./layout.module.css";

//COMPONENTS
import Sidebar from "../components/sidebar/Sidebar";
import Navbar from "../components/navbar/Navbar";
import { VersionContext } from "../../context/VersionContext";

const Layout = ({ children }) => {
  return (
    <>
      <Navbar />
      <div className={classes.layout}>
        <Sidebar />
        <div className={classes.body}>{children}</div>
      </div>
    </>
  );
};

export default Layout;
