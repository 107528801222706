import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Container,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import Layout from "../../layout/Layout";
import Visitor from "../stats/components/shared/Visitor";
import TimeRate from "../stats/components/shared/TimeRate";
import DailyStats from "../stats/components/shared/DailyStats";
import ReferralTable from "./components/ReferralTable";
import ReferralLine from "./components/ReferralLine";
import { useFetchSubscribers, useReferralStats } from "../../hooks/fetch";
import { VersionContext } from "../../../context/VersionContext";

function Referrals() {
  const { adminVersion, quiz } = useContext(VersionContext);
  const referralStats = useReferralStats({
    version: adminVersion,
    quiz_id: quiz?.id,
  });

  const [searchTerm, setSearchTerm] = useState("");
  const fetchSubscribers = useFetchSubscribers({
    version: adminVersion,
    quiz_id: quiz?.id,
  });
  return (
    <Layout>
      <Container>
        {" "}
        <Typography variant="h4" fontSize={22} fontWeight={700} mb={3}>
          Referrals
        </Typography>
        <Stack
          direction="row"
          gap={5}
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Visitor
            title="Number of Subscribers"
            visitor={referralStats?.data?.totalSubscriber}
          />
          <Visitor
            title="Number of Subscribers with Referrals"
            visitor={referralStats?.data?.subscribeWithReferral}
          />
          {/* <Visitor
            title="Number of converted Referrals"
            visitor={referralStats?.data?.convertedReferral}
          /> */}
          <Visitor title="Referral Conversion" visitor="0%" />
          {/* <Visitor title="Number of converted Referrals" visitor="27" /> */}
          {/* <TimeRate options={ [  { time: "4m:29s", title: "Highest " }]} /> */}
          <Box maxWidth={800} sx={{ width: "100%" }}>
            <ReferralLine stats={referralStats?.data?.referralByDay} />
          </Box>
        </Stack>
        <Stack mt={4} p={2} bgcolor="white" borderRadius={5}>
          <Stack
            my={2}
            justifyContent="space-between"
            alignItems="center"
            direction="row"
          >
            <TextField
              type="search"
              placeholder="Enter Search term"
              id="filled-hidden-label-small"
              size="small"
              sx={{ maxWidth: "300px" }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e?.target?.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />

            <Box>
              {/* <Button variant="contained" color="success">
                Download
              </Button> */}
            </Box>
          </Stack>
          <ReferralTable
            rows={_.filter(
              fetchSubscribers?.data?.data?.subscribers,
              function (coll) {
                // console.log({ coll });

                return (
                  coll?.name +
                  " " +
                  coll?.display_name +
                  " " +
                  coll?.email +
                  " " +
                  coll?.user_passport
                )
                  ?.toLowerCase()
                  ?.includes(searchTerm.toLowerCase());
              }
            )}
            raw={fetchSubscribers}
          />
        </Stack>
      </Container>
    </Layout>
  );
}

export default Referrals;
