import React from "react";
import Card from "./Card";
import { Box } from "@mui/material";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

function ConversionRate({ rate }) {
  return (
    <Card
      title="Conversion Rate"
      sx={{
        minWidth: "200px",
        maxWidth: "300px",
        padding: "20px",
        height: "200px",
      }}
    >
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Box
          sx={{
            height: "100px",
            width: "100px",
          }}
        >
          <CircularProgressbar value={rate} text={`${rate}%`} />
        </Box>
      </Box>
    </Card>
  );
}

export default ConversionRate;
