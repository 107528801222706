import React from "react";
import Card from "./Card";
import LineChart from "../../../../components/shared/charts/LineChart";
import { useContext } from "react";
import { VersionContext } from "../../../../context/VersionContext";
import { useGetGamePlayStats } from "../../../hooks/fetch";
import { Box } from "@mui/material";
// import faker from 'faker';

function DailyStats({ stats }) {
  const { adminVersion } = useContext(VersionContext);
  const gameStats = useGetGamePlayStats(adminVersion);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Friends subscribed by day",
      },
      labels: {
        // This more specific font property overrides the global property
        font: {
          size: 10,
        },
      },
    },
  };

  const data = {
    labels: stats?.map((data) => data?.day),
    datasets: [
      {
        label: "Friends",
        data: stats?.map((data) => data?.referral),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      //   {
      //     label: "New Subscribers",
      //     data: gameStats?.data?.subscriberByDay?.map((data) => data?.subscriber),
      //     borderColor: "rgb(53, 162, 235)",
      //     backgroundColor: "rgba(53, 162, 235, 0.5)",
      //   },
    ],
  };

  return (
    <Card
      title="Friends subscribed by day "
      sx={{ padding: "20px", width: "100%" }}
    >
      <Box sx={{ width: "100%", height: "250px" }}>
        <LineChart options={options} data={data} />
      </Box>
    </Card>
  );
}

export default DailyStats;
