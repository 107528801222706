import zainab from "./assets/zainab.png";
import nneka from "./assets/nneka.png";
import bode from "./assets/bode.png";
import yusuf from "./assets/yusuf.png";
import dami from "./assets/dami.png";
import daniel from "./assets/daniel.png";

export const Avatar = [
  {
    src: zainab,
    title: "zainab",
  },
  {
    src: nneka,
    title: "nneka",
  },
  {
    src: bode,
    title: "bode",
  },
  {
    src: yusuf,
    title: "yusuf",
  },
  {
    src: dami,
    title: "dami",
  },
  {
    src: daniel,
    title: "daniel",
  },
];

export const summer = {
  tac: [
    {
      sn: "1",
      title: "Eligibility",
      subtitle:
        " This competition is open to MTN subscribers aged 15 to 21 years only. Participants must have a valid MTN mobile number to be eligible    ",
    },
    {
      sn: "2",
      title: "Weekly Subscription",
      subtitle: `To access the weekly quizzes and participate in the competition, participants must make a weekly subscription to our platform. Only active subscribers will be allowed to play.`,
    },
    {
      sn: "3",
      title: "Quiz Participation",
      subtitle:
        "Participants must answer the quiz questions honestly and to the best of their knowledge. Cheating or using any unfair means to gain an advantage is strictly prohibited and will lead to disqualification.    ",
    },
    {
      sn: "4",
      title: "Prize Claim",
      subtitle:
        "Winners will be announced every week, and top performers will be eligible for prizes. Winners must come to our office to claim their prizes within 7 days after announcement.",
    },
    {
      sn: "5",
      title: "Past Winners",
      subtitle:
        "Winners of past 9ijakids competitions are not eligible to win in the 5 weeks of summer fun",
    },
    {
      sn: "6",
      title: "Personal Information",
      subtitle:
        "Participants consent to the collection and use of their personal information for the purpose of administering the competition and delivering prizes.",
    },
    {
      sn: "7",
      title: "Publicity",
      subtitle:
        "By participating, participants grant us the right to use their names and images for promotional purposes related to the competition.",
    },
    {
      sn: "8",
      title: "Disqualification",
      subtitle:
        "We reserve the right to disqualify any participant who violates these terms and conditions or engages in any fraudulent or suspicious activities.",
    },
    {
      sn: "9",
      title: "Changes and Termination",
      subtitle:
        "We reserve the right to modify, suspend, or terminate the competition at any time.",
    },
    {
      sn: "10",
      title: "Governing Law",
      subtitle:
        "The competition and these terms and conditions are governed by the laws of Nigeria.",
    },
  ],

  faq: [
    {
      sn: "1",
      title: "How can I participate in the quiz competitions?",
      summary:
        "All you need to do is subscribe on the MTN by using the texting “9IJA” to 8012. A weekly subscription of N50 will give you access to the quizzes",
    },
    {
      sn: "2",
      title: "What are the age categories eligible for the quiz competitions?",
      summary:
        "The quiz competitions are open to participants aged 15 to 21 years.",
    },
    {
      sn: "3",
      title: "How often can I play the quizzes?",
      summary:
        "There is no limit on the number of times you can play the quiz for the week. As a subscriber, you can play the quizzes as many times as you want during the period when the quiz is available. Only your highest score will be used.",
    },
    {
      sn: "4",
      title: "How long will the 5 Weeks of Summer Fun campaign last?",
      summary:
        "The campaign will run for a total of 6 weeks, starting from Monday, 16th August and concluding on Saturday, 23rd September, 2023.",
    },
    {
      sn: "5",
      title: "When does the quiz go live each week",
      summary:
        "The quiz starts on 12 noon every Monday and closes by 12 midnight every Sunday.",
    },
    {
      sn: "6",
      title: "Can I play the quizzes offline?",
      summary:
        "No, the quizzes require an internet connection to access and participate. Make sure you have a stable internet connection to fully enjoy the quiz competitions.",
    },
    {
      sn: "7",
      title: "How are the winners selected?",
      summary:
        "Winners are selected based on scores in the quizzes. The highest scores will top the leaderboard. Top performers each week will be eligible for prizes. And grand prize winner will be determined by the end of the campaign",
    },
    {
      sn: "8",
      title: "How can I track my progress and scores in the quizzes?",
      summary:
        "When you login, you can see your position on the leaderboard, rank and highest score.",
    },
    {
      sn: "9",
      title: "How do I claim my prize if I win?",
      summary:
        "If you are a winner, you will be announced on our platform and contacted. You will need to come to our office to claim your prize within the specified timeframe if the prize is physical.",
    },
    {
      sn: "10",
      title: "Once the challenge has started, Can I still join in?",
      summary:
        "Yes, there are prizes to be won weekly and you are welcome to participate. However, the overall winner is calculated base on scores for the first to the last week. So joining late will affect your chances of winning the overall prize.",
    },
  ],
  rules: [
    {
      sn: "1",
      text: "Only MTN subscribers are eligible to participate in the competition.",
    },
    {
      sn: "2",
      text: "Participants must be aged between 13 and 21 years old to join the 5 Weeks of Summer Fun competition.",
    },
    {
      sn: "3",
      text: "To enter the weekly quizzes, participants must make a weekly subscription to the MTN 9ijakids platform.",
    },
    {
      sn: "4",
      text: "Winners will be announced on our website and social media platforms every Sunday by 5 PM.",
    },
    {
      sn: "5",
      text: "Participants must provide accurate and valid contact information to be eligible for prize redemption.",
    },
    {
      sn: "6",
      text: "Participants must claim their prizes in person at our office within 7 days of being announced as winners.",
    },
    {
      sn: "7",
      text: "Prizes are non-transferable and cannot be exchanged for cash or any other alternatives.",
    },
    {
      sn: "8",
      text: "9ijakids reserves the right to disqualify any participant who violates the competition rules or engages in fraudulent activities.",
    },
  ],
  how: [
    {
      sn: "1",
      text: "Text 9IJA to 8012 (Only available to MTN subscribers)",
    },
    {
      sn: "2",
      text: "Play the quiz for the week",
    },
    {
      sn: "3",
      text: "Unlimited play for ₦‎50 weekly",
    },
    {
      sn: "4",
      text: "New quizzes are released weekly",
    },
    {
      sn: "5",
      text: "You have the opportunity to play multiple times and aim for the top spot on the leaderboard. Your position on the leaderboard is determined by your highest score.",
    },
    {
      sn: "6",
      text: "Weekly winners will be announced every Monday",
    },
    {
      sn: "7",
      text: "Overall grand winners to be announced at the end of the campaign – 26th September 2023",
    },
  ],
};
