// import React, { useState } from "react";
import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { Config } from "../../Config";
import { useEffect } from "react";
import { version } from "styled-components";
const adminInfo = JSON.parse(sessionStorage.getItem("adminAuth"));

// HEADERS
const config = {
  headers: {
    Authorization:
      "Bearer " + `${JSON.parse(sessionStorage.getItem("adminAuth"))?.token}`,
  },
};
console.log({ config });
async function getter(payload) {
  const { data } = await axios.get(`${Config.url.API_URL}/${payload}`, config);
  return data;
}
// SUBSCRIBER STATS
export const useSubscriberStats = (version) => {
  const subscriberStats = async () => {
    try {
      const { data } = await axios.get(
        `${Config.url.API_URL}/admin/subscribers/all?version=${version}`,
        config
      );
      console.log("data1", data);
      return data;
    } catch (error) {
      console.log("data2", { error: error.response || error });
    }
  };

  const { status, data, isFetching, refetch } = useQuery(
    ["viewSubs", version],
    () => subscriberStats(),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );
  return { status, data, isFetching, refetch };
};

// GAME STATS
export const useGamesStats = (version) => {
  const gameStats = async () => {
    try {
      const { data } = await axios.get(
        `${Config.url.API_URL}/admin/games/all?version=${version}`,
        config
      );
      console.log("data3".data);
      return data;
    } catch (error) {
      console.log("data4", { error: error.response || error });
    }
  };

  const { status, data, isFetching, refetch } = useQuery(
    ["viewGameStats", version],
    () => gameStats(),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );
  return { status, data, isFetching, refetch };
};

// REVENUE STATS
export const useRevenueStats = (version) => {
  const revenueStats = async () => {
    try {
      const { data } = await axios.get(
        `${Config.url.API_URL}/admin/revenue/all?version=${version}`,
        config
      );
      console.log("data5", data);
      return data;
    } catch (error) {
      console.log("data6", { error: error.response || error });
    }
  };

  const { status, data, isFetching, refetch } = useQuery(
    ["viewRevenueStats", version],
    () => revenueStats(),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );
  return { status, data, isFetching, refetch };
};

//  GET DATA FOR GAME SUBSCRIBERS
export const useGameChart = (version) => {
  const gameChart = async () => {
    try {
      const { data } = await axios.get(
        // `${Config.url.API_URL}/admin/chart/games`,
        `${Config.url.API_URL}/admin/chart/sub?version=${version}`,
        config
      );
      return data;
    } catch (error) {
      console.log("data8", { error: error.response || error });
    }
  };

  const { status, data, isFetching, refetch } = useQuery(
    ["GameChart", version],
    () => gameChart(),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );
  return { status, data, isFetching, refetch };
};

// get Data for game played.
export const useGamePlayed = (version) => {
  const gamePlayed = async () => {
    try {
      const { data } = await axios.get(
        `${Config.url.API_URL}/admin/chart/games?version=${version}`,
        config
      );
      return data;
    } catch (error) {
      console.log("GAME PLAYED ERROR", { error: error.response || error });
    }
  };

  const { status, data, isFetching, refetch } = useQuery(
    ["GamePlayedChart", version],
    () => gamePlayed(),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );
  return { status, data, isFetching, refetch };
};

// FETCH ALL SUBSCRIBERS
export const useAllSubscribers = (
  filterValue,
  searchTerm,
  pageSize,
  pageNumber,
  version
) => {
  console.log("data10", filterValue.profile);
  const allSubcribers = async () => {
    try {
      const { data } = await axios.get(
        `${Config.url.API_URL}/admin/subscribers?searchTerm=${searchTerm}&profile=${filterValue.profile}&status=${filterValue.status}&pageNumber=${pageNumber}&pageSize=${pageSize}&dateCreated=${filterValue.date_created}&version=${version}`,
        config
      );
      console.log("data11", data);
      return data;
    } catch (error) {
      console.log("data12", { error: error.response || error });
    }
  };

  const { status, data, isFetching } = useQuery(
    [
      "allSubs",
      filterValue.profile,
      filterValue.status,
      filterValue.date_created,
      searchTerm,
      pageSize,
      pageNumber,
      version,
    ],
    () => allSubcribers(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
    }
  );
  return { status, data, isFetching };
};
//New Fetch All Subscribers

export const useFetchSubscribers = (payload) => {
  const { version, quiz_id, page, search } = payload;
  const fetchSubscribersApi = async (payload) => {
    console.log({ config2: config, payload });
    const { data } = await axios.get(
      `${Config.url.API_URL}/admin/all-subscribers?version=${version}&quiz_id=${quiz_id}&pageNumber=${page}&search=${search} `,
      config
    );
    return data;
  };

  const querySubscribeApi = useQuery(
    ["fetch-subscribers", payload?.version, payload?.quiz_id, page, search],
    () => fetchSubscribersApi(payload)
  );

  return querySubscribeApi;
};
// SUBSCRIBBER PROFILE
export const useSubscriberProfile = (userPassport) => {
  const subscriberProfile = async () => {
    try {
      const { data } = await axios.get(
        `${Config.url.API_URL}/admin/subscribers/single/${userPassport}`,
        config
      );
      console.log("data13", data);
      return data;
    } catch (error) {
      console.log("data14", { error: error.response || error });
    }
  };

  const { status, data, isFetching, refetch } = useQuery(
    ["subProfile", userPassport],
    () => subscriberProfile(),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );
  return { status, data, isFetching, refetch };
};

// FETCH GAMES
export const useGames = (searchTerm) => {
  console.log();

  const fetchGames = async () => {
    try {
      const { data } = await axios.get(
        `${
          Config.url.API_URL
        }/admin/games?pageNumber=&pageSize=${5}&searchTerm=${searchTerm}&sortColumn ="createdAt"&sortOrder = "DESC"`,
        config
      );
      console.log("data15", data);
      return data;
    } catch (error) {
      console.log("data16", { error: error.response || error });
    }
  };

  const { status, data, isFetching, refetch } = useQuery(
    ["getGames", searchTerm],
    () => fetchGames(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
    }
  );
  return { status, data, isFetching, refetch };
};

//Fetch Quiz

export const useFetchQuiz = (version) => {
  const fetchQuiz = async (version) => {
    const { data } = await axios.get(
      `${Config.url.API_URL}/quiz/all?version=${version}`
    );
    return data;
  };

  const queryFetchQuiz = useQuery(["fetch-quiz", version], () =>
    fetchQuiz(version)
  );
  return queryFetchQuiz;
};

// GAME PROFILE
export const useGameProfile = (gameId) => {
  console.log();

  const fetchGameProfile = async () => {
    try {
      const { data } = await axios.get(
        `${Config.url.API_URL}/admin/games/single/${gameId}`,
        config
      );
      console.log("data18", data);
      return data;
    } catch (error) {
      console.log("data19", { error: error.response || error });
    }
  };

  const { status, data, isFetching, refetch } = useQuery(
    ["gameProfile", gameId],
    () => fetchGameProfile(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
    }
  );
  return { status, data, isFetching, refetch };
};

// FETCH PLANS
export const usePlans = (searchTerm) => {
  const plan = async () => {
    try {
      const { data } = await axios.get(
        `${Config.url.API_URL}/admin/plans?searchTerm=${searchTerm}`,
        config
      );
      console.log("data20", data);
      return data;
    } catch (error) {
      console.log("data21", { error: error.response || error });
    }
  };

  const { status, data, isFetching, refetch } = useQuery(
    ["plan", searchTerm],
    () => plan(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
    }
  );
  return { status, data, isFetching, refetch };
};

// PLAN PROFILE
export const usePlanProfile = (id) => {
  const planProfile = async () => {
    try {
      const { data } = await axios.get(
        `${Config.url.API_URL}/admin/plans/single/${id}`,
        config
      );
      console.log("data22", data);
      return data;
    } catch (error) {
      console.log("data23", { error: error.response || error });
    }
  };

  const { status, data, isFetching, refetch } = useQuery(
    ["planProfile"],
    () => planProfile(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
    }
  );
  return { status, data, isFetching, refetch };
};

//Fetch Game Play Stats

export const useGetGamePlayStats = (payload) => {
  let { version, quizId } = payload;
  const fetchGamePlayStats = async (version, quizId) => {
    const { data } = await axios.get(
      `${Config.url.API_URL}/admin/overall-summary?version=${version}&quiz_id=${quizId}`
    );
    return data;
  };
  const queryGamePlayStats = useQuery(
    ["fetch-game-play-stats", version, quizId],
    () => fetchGamePlayStats(version, quizId)
  );
  return queryGamePlayStats;
};

export const useReferralStats = (payload) => {
  let { version, quiz_id } = payload;
  const data = (version, quiz_id) =>
    getter(`admin/referral-summary?version=${version}&quiz_id=${quiz_id}`);
  const queryReferralStats = useQuery(
    ["fetch-referral", payload?.version, payload?.quiz_id],
    () => data(version, quiz_id)
  );
  return queryReferralStats;
};
